import SenseiOsakaBannerAlertContent from 'components/senseiOsaka/BannerAlertContent';
import SenseiOsakaOverlayAlertContent from 'components/senseiOsaka/OverlayAlertContent';

import FujiBannerAlertContent from 'components/fuji/BannerAlertContent';
import FujiOverlayAlertContent from 'components/fuji/OverlayAlertContent';
import DinerSignupAlertContainer from 'components/DinerSignupAlertContainer';

export const OVERLAY_TEMPLATE_COMPONENT_MAP = {
  SENSEI_OSAKA: SenseiOsakaOverlayAlertContent,
  FUJI: FujiOverlayAlertContent,
  // TODO once the DinerSignup epic has progressed more, we'll probably want
  // an AlertContainer` to be a generic component that can be used for any alert template and
  // the content thats currently in DinerSignupAlertContainer, should be for just promotion
  PROMOTION: DinerSignupAlertContainer,
  ANNOUNCEMENT: DinerSignupAlertContainer
};

export const BANNER_TEMPLATE_COMPONENT_MAP = {
  SENSEI_OSAKA: SenseiOsakaBannerAlertContent,
  FUJI: FujiBannerAlertContent
};
